import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate';

import drehtuer_00 from '../../images/automatische_drehtuer_00_1600.webp'
import drehtuer_01 from '../../images/automatische_drehtuer_01_1600.webp'
import drehtuer_02 from '../../images/automatische_drehtuer_02_1600.webp'
import drehtuer_03 from '../../images/automatische_drehtuer_03_1600.webp'
import drehtuer_04 from '../../images/automatische_drehtuer_04_1600.webp'
import drehtuer_05 from '../../images/automatische_drehtuer_05_1600.webp'
import drehtuer_thumb_00 from '../../images/automatische_drehtuer_00_400.webp'
import drehtuer_thumb_01 from '../../images/automatische_drehtuer_01_400.webp'
import drehtuer_thumb_02 from '../../images/automatische_drehtuer_02_400.webp'
import drehtuer_thumb_03 from '../../images/automatische_drehtuer_03_400.webp'
import drehtuer_thumb_04 from '../../images/automatische_drehtuer_04_400.webp'
import drehtuer_thumb_05 from '../../images/automatische_drehtuer_05_400.webp'

const context = {
  title: 'Automatische Drehtür',
  description: 'Barrierefreier Zugang im Innen- und Außenbereich, universell für jegliche Art von Türen, auch zur Nachrüstung',
  text: 'Eine automatische Drehtür besteht aus einer ein- oder zweiflügeligen Drehtür, welche sich ziehend oder drückend mit Hilfe eines Drehtürantriebs automatisch öffnet. Die Drehtürantriebe können sowohl an einfachste Innentüren ohne weitere Anforderungen als auch an schwere, einbruchssichere Außentüren für Flucht- und Rettungswege oder an Brandschutztüren montiert werden. Auch eine Nachrüstung ist in der Regel jederzeit möglich. \n\n' +

  'Die maximale Flügelbreite dieser automatisierten Drehtür liegt bei 1600 mm. In Abhängigkeit von der Flügelbreite liegt das maximale Türflügelgewicht je Antrieb bei bis zu 600 kg. Automatische Drehtüren ermöglichen es jede Eingangs- oder Durchgangssituation barrierefrei, hygienisch und zeitgemäß zu gestalten. Drehtürantriebe können über Sensorik oder Taster angesteuert werden. Auch lassen sie sich beispielsweise mit einem Zutrittskontrollsystem kombinieren. \n\n' +

  'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung. ',
  features: [
    //'autschr',
    'barrierefreiheit',
    'brandschutz',
    'breakOut',
    //'design',
    //'din18650',
    'energieeinsparung',
    'modernisierung',
    //'notStrom',
    //'personenfrequenz',
    //'platzsparend',
    //'transparenz',
    'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    //'autschr',
    'din18650',
    'dinEn170',
    'eltvtr',
  ],
  carouselImages: [
    {
      original: drehtuer_00,
      thumbnail: drehtuer_thumb_00,
      originalAlt: 'Automatische Drehtür'
    },
    {
      original: drehtuer_01,
      thumbnail: drehtuer_thumb_01,
      originalAlt: 'Automatische Drehtür'
    },
    {
      original: drehtuer_02,
      thumbnail: drehtuer_thumb_02,
      originalAlt: 'Automatische Drehtür'
    },
    {
      original: drehtuer_03,
      thumbnail: drehtuer_thumb_03,
      originalAlt: 'Automatische Drehtür'
    },
    {
      original: drehtuer_04,
      thumbnail: drehtuer_thumb_04,
      originalAlt: 'Automatische Drehtür'
    },
    {
      original: drehtuer_05,
      thumbnail: drehtuer_thumb_05,
      originalAlt: 'Automatische Drehtür'
    },
  ],
  configurator: false,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
