import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import {Link} from 'gatsby';
import ImageGallery from 'react-image-gallery';
import * as entities from 'entities';
import ProductPageIcon from './ProductPageIcon';
import cta01 from '../images/berater_04.webp'


const ProductPageTemplate = ({ pageContext }) => {

  const carouselConfig = {
    showThumbnails: true,
    showFullscreenButton: false,
    showPlayButton: false,
    showBullets: false,
    autoPlay: false,
    showNav: false,
    slideInterval: 3000,
    slideDuration: 600,
  }

  const thumbStyle = () => {
    return ({
      border: '5px solid red'
    })
  }

  return (
    <div className="application">
      <Helmet>
        <title>{entities.decodeHTML(pageContext.title)} | Automatiktür24</title>
        <meta name="description" content={entities.decodeHTML(pageContext.title) + ' via Automatiktür24 konfigurieren'} />
        <meta charSet="utf-8" />
        <html lang="de" />
      </Helmet>
      <Layout>

        <section className="pt-10">
          <div className="container mx-auto px-8 lg:flex">

            <div className="w-full">
              <p className="text-md mb-3 font-light">
                <Link to="/#produkte">← &nbsp; zurück zur Übersicht</Link>
              </p>
              <p className="text-lg lg:text-xl mb-3 font-light">
                Produktdetails
              </p>
              <h1 className="text-2xl lg:text-3xl xl:text-5xl font-bold leading-none">
                {entities.decodeHTML(pageContext.title)}
              </h1>
            </div>

          </div>

          <div className="container mx-auto px-8 lg:flex">

            <div className="lg:w-1/2">
              <div className="pb-10 mt-12">
                <ImageGallery items={pageContext.carouselImages} {...carouselConfig} thumbnailStyle={thumbStyle}/>
              </div>
              <p className="pb-3 lg:text-xl font-semibold">
                {entities.decodeHTML(pageContext.description)}
              </p>
              <p style={{whiteSpace: 'pre-line'}}>
                {entities.decodeHTML(pageContext.text)}
              </p>
            </div>

            <div className="lg:w-1/2 lg:pl-5 xl:pl-10 md:flex lg:flex">

              <div className="pb-10 md:w-1/2 lg:w-2/3">
                <p className="text-lg lg:text-xl font-light mt-12 mb-4">
                  Vorteile auf einen Blick
                </p>
                <ul>
                  {pageContext.features.map(feature => (
                    <li key={feature}>
                      <ProductPageIcon attribute={feature} />
                    </li>
                  ))}
                </ul>
                <p className="text-lg lg:text-xl font-light mt-12 mb-4">
                  Erfüllt Anforderungen nach
                </p>
                <ul>
                  {pageContext.legalRequirements.map(requirement => (
                    <li key={requirement}>
                      <ProductPageIcon attribute={requirement} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:w-1/2 lg:w-1/3">
                {(pageContext.configurator === true) ?
                  <div className="lg:w-48 mb-6">
                    <Link to="/automatiktuer-konfigurator/"
                          className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white py-4 text-center block rounded shadow-xl">Online
                      konfigurieren</Link>
                  </div>
                  : ''
                }
                <div className="lg:w-48 mb-6">
                  <Link to="/kontakt/" className="bg-gray-400 hover:bg-gray-500 text-white hover:text-white py-4 text-center block rounded shadow-xl">Kontakt aufnehmen</Link>
                </div>

                <div className="w-48 rounded overflow-hidden shadow-xl">
                  <img className="w-full" src={cta01} alt="Angebot einfordern" />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Max Brunner</div>
                    <p className="text-gray-700 text-sm">
                      Individuelle Beratung
                      <br/>
                      Tel. <a href="tel:+4975245092939">07524 / 509 29 39</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    </div>
  );
}

export default ProductPageTemplate

